@import './style/global/variables.css';

#root {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: var(--primary-font);
  color: var(--primary-color-darker);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--secondary-font);
}

a {
  font-weight: var(--fw-bolder);
  color: var(--primary-color-darker);
  transition: all .3s ease-in-out;
}

a:hover {
  color: var(--primary-color);
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.lead {
  font-weight: var(--fw-bold);
}

img {
  max-width: 100%;
}