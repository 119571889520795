.imgTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ingress {
  font-size: 1.10rem;
}

.headingBox {
  display: block;
  position: relative;
  top: -25px;
  background: var(--white);
  padding: 10px 40px;
  min-width: 200px;
  text-align: center;
  border: 1px solid var(--primary-color-darker);
  box-shadow: 5px 5px 0 0 var(--primary-color-darker);
}

.headingBox p {
  font-weight: var(--fw-regular);
}
