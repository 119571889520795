.textAndImageContainer a {
    opacity: 0.9;
    transition: 0.3s;
}

.textAndImageContainer a:after {
    content: url('../media/arrow.svg');
    margin-left: 10px;
    position: relative;
}

.textAndImageContainer p {
    width: 100%;
}

@media (min-width: 992px) {
    .firstImg {
        margin-top: 140px;
    }

    .thirdImg {
        margin-top: 80px;
    }
}

button.arrow {
    border: 0;
    background: transparent;
    font-weight: var(--fw-bolder);
    text-decoration: underline;
    padding: 0;
    color: var(--black);
}

button.arrow:hover {
    color: var(--primary-color);
    transition: all .3s ease-in-out;
}

.arrow:after {
    content: url('../media/arrow.svg');
    margin-left: 10px;
    position: relative;
    transform: rotate(90deg);
    display: inline-block;
}

.arrowUp:after {
    transform: rotate(270deg);
    top: -2px;
}