.circleContainer {
    height: 450px;
}

.smallCircle {
  height: 320px;
  width: 320px;
  border-radius: 50%;
  position: absolute;
  top: 150px;
  right: 300px;
  background-color: var(--primary-color-dark-opacity);
}

.bigCircle {
  height: 450px;
  width: 450px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  background-color: var(--primary-color-opacity);
}

.bigCircle img {
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

@media (max-width: 992px) {
    .smallCircle {
        width: 250px;
        height: 250px;
        top: 70px;
        left: 8%;
        right: initial;
    }

    .bigCircle {
        right: 5%;
        height: 350px;
        width: 350px;
    }

    .circleContainer {
        height: 350px;
    }
}

@media (max-width: 480px) {
    .smallCircle {
        width: 180px;
        height: 180px;
    }

    .bigCircle {
        height: 250px;
        width: 250px;
    }

    .circleContainer {
        height: 250px;
    }
}


@media (max-width: 350px) {
    .smallCircle {
        width: 120px;
        height: 120px;
    }

    .bigCircle {
        height: 200px;
        width: 200px;
    }

    .circleContainer {
        height: 200px;
    }
}
