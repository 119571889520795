:root {
  --black: #000000;
  --white: #ffffff;
  --primary-color: #9a8478;
  --primary-color-dark: #653e2f;
  --primary-color-darker: #212121;
  --primary-color-light: #fba92c;
  --primary-color-lighter: #ffead1;
  --primary-color-opacity: rgba(154, 132, 120, 30%);
  --primary-color-dark-opacity: rgba(101, 62, 47, 30%);
  --primary-font: 'Montserrat', sans-serif;
  --secondary-font: 'Lora', serif;
  --signature-font: 'Hurricane', cursive;
  --fw-light: 300;
  --fw-regular: 400;
  --fw-bold: 500;
  --fw-bolder: 700;
}

.primary-color {
  color: var(--primary-color);
}

.primary-color-dark {
  color: var(--primary-color-dark);
}

.primary-color-darker {
  color: var(--primary-color-darker);
}

.primary-color-light {
  color: var(--primary-color-light);
}

.primary-color-lighter {
  color: var(--primary-color-lighter);
}

.bg-primary-color {
  background-color: var(--primary-color);
}

.bg-primary-color-dark {
  background-color: var(--primary-color-dark);
}

.bg-primary-color-darker {
  background-color: var(--primary-color-darker);
}

.bg-primary-color-light {
  background-color: var(--primary-color-light);
}

.bg-primary-color-lighter {
  background-color: var(--primary-color-lighter);
}

.primary-font {
  font-family: var(--primary-font);
}

.secondary-font {
  font-family: var(--secondary-font);
}

.signature {
  font-family: var(--signature-font);
  font-size: 2.3rem;
}