.readMoreArrow {
    transform: rotate(90deg);
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 35px;
    right: 0;
    animation: bounce 2s ease infinite;
}

@keyframes bounce {
    0%, 100% {
        bottom: 50px;
    }
    50% {
        bottom: 80px;
    }
}