/* ---------- */
/*    HERO    */
/* ---------- */
.hero {
    height: 500px;
  }
  
  .hero img {
    height: 100%;
    width: 98%;
  }
  
  .hero h1 {
    font-size: 60px;
  }
  
  .hero p {
    font-size: 28px;
    font-family: primary-font;
  }
  
  @media screen and (max-width: 767px) {
    .hero {
      height: 480px;
    }
  
    .hero h1 {
      font-size: 45px;
    }
    .hero p {
      font-size: 20px;
    }
  }
  
  @media screen and (max-width: 500px) {
    .hero {
      height: 450px;
    }
  
    .hero h1 {
      font-size: 35px;
    }
    .hero p {
      font-size: 18px;
    }
  }
  
  @media screen and (max-width: 395px) {
    .hero {
      height: 350px;
    }
    .hero h1 {
      font-size: 30px;
    }
  }

  