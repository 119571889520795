#blackbird-navbar {
  background-color: var(--primary-color-lighter);
}

#blackbird-navbar-label {
  color: var(--primary-color-darker);
}

.nav-list {
  list-style-type: none;
  padding: 0;
}

.nav-list li {
  position: relative;
}

.nav-list li.active a::before {
  width: 40%;
  opacity: 1;
  height: 3px;
}

.nav-list li a {
  display: block;
  padding: 12px 0;
  color: var(--primary-color-darker);
  font-weight: 600;
  text-decoration: none;
  transition-duration: 0.25s;
}

.nav-list li a::before {
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: -20px;
  position: absolute;
  background: var(--primary-color-light);
  opacity: 0.6;
  transition: width 0.5s ease 0s, opacity 0.5s ease 0s;
  width: 0;
}

.nav-list li:hover a {
  color: #6B4000;
}

.nav-list li:hover a::before {
  width: 40%;
  opacity: 1;
}

/* .hamburger-menu {
  position: absolute;
  right: 40px;
  top: 40px;
}

#menu__toggle {
  opacity: 0;
  width: 26px;
  height: 26px;
  position: absolute;
}
#menu__toggle:checked + .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
}
#menu__toggle:checked + .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
  right: 0 !important;
}
.menu__btn {
  position: relative;
  top: -6px;
  width: 26px;
  cursor: pointer;
  z-index: 999;
}
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--black);
  transition-duration: 0.25s;
}
.menu__btn > span::before {
  content: '';
  top: -8px;
}
.menu__btn > span::after {
  content: '';
  top: 8px;
}
.menu__box {
  display: block;
  position: fixed;
  top: 0;
  right: -100%;
  width: 300px;
  height: 100%;
  margin: 0;
  padding: 80px 0;
  list-style: none;
  background-color: var(--primary-color-dark);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  transition-duration: 0.25s;
  z-index: 99;
}
.menu__item {
  display: block;
  padding: 12px 24px;
  color: var(--primary-color-light);
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition-duration: 0.25s;
}
.menu__item:hover {
  color: var(--primary-color-lighter);
}
.menu__item:active {
  color: var(--primary-color-lighter);
} */
