.parent {
    width: 100%;
    position: relative;
    height: 520px;
}

.leftCircle {
    background-color: var(--primary-color-opacity);
    width: 500px;
    height: 500px;
    border-radius: 50%;
    position: absolute;
}

.text {
    z-index: 10;
}

.rightCircle {
    background-color: var(--primary-color-dark-opacity);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    right: 0px;
    top: 0px;
    position: absolute;
}

@media (max-width: 500px) {
    .parent {
        height: 390px;
    }

    .leftCircle {
        width: 320px;
        height: 320px;
        bottom: 30px;
    }
    .rightCircle {
        width: 290px;
        height: 290px;
    }
}